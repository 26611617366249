//字典
export default {
  //商品审核状态: 0下架,1上架审核通过, 2 待审核,3未通过
  goodsAuditStatus: [
    {
      code: 0,
      text: '下架中',
    },
    {
      code: 1,
      text: '已上架',
    },
    {
      code: 2,
      text: '待审核',
    },
    {
      code: 3,
      text: '未通过审核',
    },
  ],
  //专区类别：1一号专区；2非一号专区
  zoneTypes: [
    {
      code: 1,
      text: '一号专区',
    },
    {
      code: 2,
      text: '普通商品',
    },
  ],
  //是否包邮：1.包邮，0不包邮
  isContainFreight: [
    {
      code: 0,
      text: '不包邮',
    },
    {
      code: 1,
      text: '包邮',
    },
  ],
  // 支付状态（0：支付失败 1：支付成功 2：待支付 3：线下已付款 102：申请售后）
  paymentState: [
    {
      code: 0,
      text: '支付失败',
    },
    {
      code: 1,
      text: '支付成功',
    },
    {
      code: 2,
      text: '待支付',
    },
  ],
  //  订单状态  0待支付,3.待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
  orderState: [
    {
      code: 0,
      text: '待支付',
    },
    {
      code: 3,
      text: '待发货',
    },
    {
      code: 4,
      text: '待收货/已发货',
    },
    {
      code: 5,
      text: '已完成/未评价',
    },
    {
      code: 6,
      text: '已评价',
    },
    {
      code: 7,
      text: '退款成功',
    },
    {
      code: 8,
      text: '申请退款',
    },
  ],
  //   付款方式1微信,2支付宝,3paypal10提货券,11支付宝+提货券,12微信+提货券
  paymentType: [
    { code: 1, text: '微信' },
    { code: 2, text: '支付宝' },
    { code: 3, text: 'paypal' },
    { code: 4, text: '提货券兑换' },
    { code: 5, text: '线下预存货款' },
    { code: 6, text: '微信+提货券' },
    { code: 7, text: '支付宝+提货券' },
    { code: 8, text: '微信+贝' },
    { code: 9, text: '支付宝+贝' },
    { code: 10, text: '提货券' },
    { code: 11, text: '支付宝+提货券' },
    { code: 12, text: '微信+提货券' },
    { code: 13, text: '搜了付' },
    { code: 15, text: '微信+线下支付' },
    { code: 16, text: '支付宝+线下支付' },
    { code: 17, text: '银联' },
    { code: 18, text: '银联+提货券' },
    { code: 20, text: '拉卡拉支付宝' },
    { code: 21, text: '拉卡拉微信' },
    { code: 22, text: '拉卡拉银联' },
    { code: 23, text: '拉卡拉大额预存货款' },
    { code: 24, text: '拉卡拉快捷支付' },
    { code: 101, text: '好收银支付宝' },
    { code: 102, text: '好收银微信' },
    { code: 103, text: '好收银云闪付' },
    { code: 104, text: '货款支付' },
    { code: 110, text: 'soolePay微信' },
    { code: 111, text: 'soolePay支付宝' },
    { code: 112, text: 'soolePay银联' },
    { code: 113, text: 'soolePay云账户' },
    { code: 114, text: 'soolePay云闪付' },
  ],

  paymentChannel: [
    { value: 1, label: '微信' },
    { value: 2, label: '支付宝' },
    { value: 3, label: 'paypal' },
    { value: 4, label: '提货券兑换' },
    { value: 5, label: '线下预存货款' },
    { value: 6, label: '微信+提货券' },
    { value: 7, label: '支付宝+提货券' },
    { value: 8, label: '微信+贝' },
    { value: 9, label: '支付宝+贝' },
    { value: 10, label: '提货券' },
    { value: 11, label: '支付宝+提货券' },
    { value: 12, label: '微信+提货券' },
    { value: 13, label: '搜了付' },
    { value: 15, label: '微信+线下支付' },
    { value: 16, label: '支付宝+线下支付' },
    { value: 17, label: '银联' },
    { value: 18, label: '银联+提货券' },
    { value: 20, label: '拉卡拉支付宝' },
    { value: 21, label: '拉卡拉微信' },
    { value: 22, label: '拉卡拉银联' },
    { value: 23, label: '拉卡拉大额预存货款' },
    { value: 24, label: '拉卡拉快捷支付' },
    { value: 101, label: '好收银支付宝' },
    { value: 102, label: '好收银微信' },
    { value: 103, label: '好收银云闪付' },
    { value: 104, label: '货款支付' },
    { value: 110, label: 'soolePay微信' },
    { value: 111, label: 'soolePay支付宝' },
    { value: 112, label: 'soolePay银联' },
    { value: 113, label: 'soolePay云账户' },
    { value: 114, label: 'soolePay云闪付' },
  ],
  // 支付方式



  //  订单状态 0待支付,3待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
  orderStatus: [
    {
      code: 0,
      text: '待支付',
    },
    {
      code: 1,
      text: '待自提',
    },
    {
      code: 3,
      text: '待发货',
    },
    {
      code: 4,
      text: '待收货/已发货',
    },
    {
      code: 5,
      text: '已完成/未评价',
    },
    {
      code: 6,
      text: '已评价',
    },

    {
      code: 7,
      text: '退款成功',
    },

    {
      code: 8,
      text: '申请退款',
    },

    {
      code: 11,
      text: '退款售后',
    },

    {
      code: 12,
      text: '售后关闭',
    },

  ],
  //退款状态
  refundState: [
    {
      code: 0,
      text: '未审核',
    },
    {
      code: 1,
      text: '同意',
    },
    {
      code: 2,
      text: '不同意',
    },
  ],
  //商品参与活动状态：0已下架 1上架中 2待上架
  goodsActivityStatus: [
    {
      code: 0,
      text: '已下架',
    },
    {
      code: 1,
      text: '上架中',
    },
    {
      code: 2,
      text: '待上架',
    },
  ],
  //进货订单 ---订单状态
  restockOrderState: [
    {
      code: 0,
      text: '待支付',
    },
    {
      code: 3,
      text: '待发货',
    },
    {
      code: 4,
      text: '待入仓',
    },
    {
      code: 5,
      text: '已入仓',
    },
  ],
  // 开售状态
  canBuyStatus: [
    {
      code: 1,
      text: '已开售',
    },
    {
      code: 0,
      text: '未开售',
    },
  ],
  //物流状态
  wuliuStatus: [
    {
      code: 0,
      text: '无记录',
    },
    {
      code: 1,
      text: '揽收',
    },
    {
      code: 2,
      text: '运输中',
    },
    {
      code: 3,
      text: '派件中',
    },
    {
      code: 4,
      text: '已签收',
    },
    {
      code: 5,
      text: '拒签',
    },
    {
      code: 6,
      text: '疑难',
    },
    {
      code: 7,
      text: '退回',
    },
    {
      code: 8,
      text: '退签',
    },
    {
      code: 9,
      text: '转单',
    },
  ],
  // 活动类型
  activeType: [
    {
      code: 0,
      text: '全时段',
    },
    {
      code: 1,
      text: '部分时段',
    },
  ],
  // 活动状态
  activeStatus: [
    {
      code: 0,
      text: '未开始',
    },
    {
      code: 1,
      text: '进行中',
    },
    {
      code: 2,
      text: '已结束',
    },
  ],
  joinStatus: [
    {
      code: 0,
      text: '已参加',
    },
    {
      code: 1,
      text: '未参加',
    },
  ],
  applicationStatus: [
    {
      code: 0,
      text: '待审核',
    },
    {
      code: 1,
      text: '审核通过',
    },
    {
      code: 2,
      text: '驳回+',
    },
  ],
  // 售后状态
  afterSales: {
    0: '待审核', 1: '拒绝退货', 2: '同意退货', 3: '退货申请平台介入', 4: '待退货', 5: '已退货', 6: '待退款', 7: '拒绝退款', 8: '平台介入退款', 9: '同意退款', 10: '取消售后', 11: '已关闭', 12: '已退款'
  },
  // 店铺类型
  shopType: {
    0: '普通店铺', 1: '加盟店', 3: '厂家店铺'
  },
  //售后状态
  afterStatus: [
    {
      label: "待审核",
      value: "REVIEWED",
    },
    {
      label: "拒绝退货",
      value: "REFUSAL_RETURN_GOODS",
    },
    {
      label: "同意退货",
      value: "AGREE_RETURN_GOODS",
    },
    {
      label: "退货平台介入",
      value: "RETURN_GOODS_PLATFORM_INTERVENES",
    },
    {
      label: "待退货",
      value: "RETURNED",
    },
    {
      label: "已退货",
      value: "RETURNED_GOODS",
    },
    {
      label: "待退款",
      value: "PENDING_REFUND",
    },
    {
      label: "拒绝退款",
      value: "REFUSAL_REFUND",
    },
    {
      label: "退款平台介入",
      value: "REFUND_PLATFORM_INTERVENES",
    },
    {
      label: "同意退款",
      value: "REFUND_SUCCESSFUL",
    },
    {
      label: "取消售后",
      value: "CANCEL",
    },
    {
      label: "关闭",
      value: "SHUT_DOWN",
    },
  ],

  // 角色

  roleType: {
    0: '卖家', 1: '平台', 2: '买家', 3: '财务',
  },
  // 子订单状态
  sonOrdeState: {
    0: '待支付', 1: '待上级确认', 2: '已付现金未付贝', 3: '待发货', 4: '待收货', 5: '已完成', 6: '已评价', 7: '退款成功', 8: '申请退款', 9: '充值失败中间状态', 10: '土地已确认', 11: '退款售后', 12: '售后关闭'
  },

};
